import React, { Fragment } from "react";

import dayjs from "dayjs";

import type { ActiveALSProduct } from "~/model/products";
import type {
	LeadsListCreditReportMeta,
	LeadsListTradeToolMeta,
} from "~/model/leads";

import {
	transformToPhone,
	timestampToDateTime,
	transformToCurrency,
	toProperCase,
} from "@utils/transform";

import { getScoreRange } from "@utils/leads";

import { ITableColumn } from "~/components/Table";
import { ActiveProduct, Products } from "~/components/Products";
import Checkpoints from "@id/components/Checkpoints";

import { Box, Chip } from "@material-ui/core";

import { brandingColor } from "~/styles/colors";

import { JourneyStage, LeadState } from "@api/graphql/types";

const formatLeadStatus = (state: LeadState) => {
	switch (state) {
		case LeadState.New:
			return "New";
		case LeadState.AppointmentBooked:
			return "Appointment booked";
		case LeadState.Contacted:
			return "Contacted";
		case LeadState.Dead:
			return "Dead";
		case LeadState.Sold:
			return "Sold";
		case LeadState.Working:
			return "Working";
		default:
			return "New";
	}
};

const commonLeadColumns: ITableColumn[] = [
	{
		label: "Full Name",
		property: ["firstName", "lastName", "meta", "leadJourneys"],
		width: 300,
		cell: ([firstName, lastName, meta, leadJourneys]) => (
			<Fragment>
				{toProperCase([firstName, lastName].join(" "))}
				<Box
					style={{ display: "flex", flexDirection: "column", gap: 6 }}
				>
					{meta?.testLead && (
						<Chip
							style={{
								marginLeft: 6,
								background: `${brandingColor}`,
								color: "white",
								alignSelf: "center",
								padding: 4,
								fontWeight: 100,
							}}
							size="small"
							label="Test"
						/>
					)}
					{meta?.productVariant === "msa" && (
						<Chip
							variant="outlined"
							style={{
								marginLeft: 6,
								borderColor: `${brandingColor}`,
								color: `${brandingColor}`,
								alignSelf: "center",
								padding: 4,
								fontWeight: 100,
							}}
							size="small"
							label="MSA"
						/>
					)}
					{leadJourneys?.nodes.find(
						(j: LeadJourney) => j.stage === JourneyStage.CreditFull,
					) && (
							<Chip
								variant="outlined"
								style={{
									marginLeft: 6,
									borderColor: `${brandingColor}`,
									color: `${brandingColor}`,
									alignSelf: "center",
									padding: 4,
									fontWeight: 100,
								}}
								size="small"
								label="Full App"
							/>
						)}
				</Box>
			</Fragment>
		),
	},
	{
		label: "Phone",
		property: "phoneNumber",
		width: 225,
		cell: (props): string => transformToPhone(props.join(""), true),
	},
	{
		label: "Date Applied",
		property: "createdAt",
		width: 260,
		cell: (props): string => timestampToDateTime(dayjs(props[0] as string)),
	},
	{
		label: "Email",
		property: "emailAddress",
		width: 300,
	},
	{
		label: "Assignee",
		property: "assignee",
		width: 260,
	},
	{
		label: "Status",
		property: "state",
		width: 260,
		cell: (props): string => formatLeadStatus(props[0] as LeadState),
	},
];

interface LeadJourney {
	stage: string;
	product: {
		id: string;
		name: string;
	};
}

const extractProduct =
	(leadJourneys: LeadJourney[]) => (product: string, completed: string) => {
		const arr =
			leadJourneys?.filter((j) => j?.product?.name === product) || [];
		if (!arr.length) {
			return null;
		}

		const active = !!arr.find((j) => j?.stage === completed);

		return { product, active };
	};

const extractProducts = ({
	nodes: leadJourneys,
}: {
	nodes: LeadJourney[];
}): ActiveProduct[] => {
	const extract = extractProduct(leadJourneys);
	const CREDIT = extract("CREDIT_TOOL", "CREDIT_SCORE");
	const ID = extract("ID_TOOL", "ID_COMPLETE");
	const INCOME = extract("INCOME_TOOL", "INCOME_COMPLETE");
	const TRADE = extract("TRADE_TOOL", "TRADE");
	const DRIVE = extract("DRIVE_TOOL", "DRIVE_PARTIAL");

	return [CREDIT, TRADE, DRIVE, ID, INCOME].filter(
		Boolean,
	) as ActiveProduct[];
};

export const leadColumns = (
	isAutocorpAdmin: boolean,
	selectedProduct: ActiveALSProduct,
): ITableColumn[] => {
	const cityOrCompany = !isAutocorpAdmin
		? {
			label: "City",
			property: "city",
			cell: (props: string): string =>
				props && toProperCase(props[0]?.toString()),
			width: 180,
		}
		: {
			label: "Company",
			property: "company.name",
			cell: (props: string): string =>
				props && toProperCase(props[0]?.toString()),
			width: 180,
		};

	switch (selectedProduct) {
		case "GLOBAL":
			return [
				...commonLeadColumns,
				cityOrCompany,
				{
					label: "Products",
					property: "leadJourneys",
					align: "left",
					cell(props) {
						const products = extractProducts(props[0]);

						return (
							<div
								style={{
									display: "flex",
									flexFlow: "row nowrap",
									justifyContent: "flex-start",
									alignItems: "center",
								}}
							>
								<Products products={products} />
							</div>
						);
					},
				},
			];

		case "CREDIT_TOOL":
			return [
				...commonLeadColumns,
				cityOrCompany,
				{
					label: "Credit Range",
					property: "avaApp",
					align: "left",
					width: 170,
					cell(props) {
						const meta = props[0] as
							| LeadsListCreditReportMeta
							| undefined;
						const creditRange = getScoreRange(meta);

						return (
							<div
								style={{
									display: "flex",
									flexFlow: "row nowrap",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								{`${creditRange} `}
							</div>
						);
					},
				},
			];

		case "TRADE_TOOL":
			return [
				...commonLeadColumns,
				cityOrCompany,
				{
					label: "Trade Value",
					property: "tradeApps",
					align: "left",
					width: 150,
					cell(props) {
						const tradeApp = props[0] as
							| LeadsListTradeToolMeta
							| undefined;
						const tradeInValue =
							(tradeApp && tradeApp.nodes[0]?.tradeInValue) || "";
						return (
							(tradeInValue &&
								transformToCurrency(tradeInValue)) ||
							""
						);
					},
				},
			];

		case "ID_TOOL":
			return [
				...commonLeadColumns,
				cityOrCompany,
				{
					label: "Steps Completed",
					property: "leadJourneys",
					align: "left",
					width: 300,
					cell(props) {
						return <Checkpoints journeyStages={props[0]?.nodes} />;
					},
				},
			];

		case "LENDER_TOOL":
			return [];
		case "TRADE_PRO":
		default:
			return [];
	}
};
