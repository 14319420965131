import React from "react";
import { Box, MenuItem, Select } from "@material-ui/core";

import { Typography } from "~/components/Typography";
import { useUserQuery } from "@api/users";

import { customStyles } from "./styles";

export const Assignee = ({
	value,
	handleChange,
}: {
	value: string;
	handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}) => {
	const { groups } = useUserQuery();
	const usersGroup = groups?.[0]?.users || [];
	const options = usersGroup?.map((user) => ({
		label: user.email,
		value: user.email,
	}));
	const styles = customStyles();

	if (!usersGroup.length) {
		return <Box />;
	}

	const allOptions = [
		{ label: "All", value: "" },
		{ label: "Unassigned", value: "unassigned" },
		...options,
	];

	return (
		<div className={styles.selectOption}>
			<Typography variant="caption" className={styles.selectLabel}>
				Assignee:
			</Typography>
			<Select
				placeholder="Assignee"
				id="select-lead-asignee"
				className={styles.select}
				value={value}
				onChange={handleChange}
				MenuProps={{
					getContentAnchorEl: () => null!,
				}}
			>
				{allOptions.map(({ label, value }) => {
					return (
						<MenuItem key={`user-status-${value}`} value={value}>
							{label}
						</MenuItem>
					);
				})}
			</Select>
		</div>
	);
};
