import React, { useState, useMemo, useCallback, useEffect } from "react";

import clsx from "clsx";
import dayjs from "dayjs";
import yn from "yn";

import { mp } from "@utils/mixpanel";
import { stringifyQuery } from "@utils/url";
import { dateToInternal } from "@utils/transform";
import type { INavigateFn } from "@utils/navigate";
import { ILeadsFilterParams, LeadsFilterInputHandler } from "@utils/leads";

import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ResetIcon from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";

import { PrimaryButton } from "~/components/Button";
import { Input } from "~/components/Input";
import { DatePicker } from "~/components/DatePicker";

import { customStyles } from "./styles";
import { MenuItem, Select } from "@material-ui/core";
import { Typography } from "~/components/Typography";
import { LeadPriority, LeadState } from "@api/graphql/types";

const DateFilterComponent: typeof DatePicker = (props) => {
	return (
		<DatePicker
			{...props}
			style={{
				width: "auto",
			}}
		/>
	);
};

interface IFiltersProps {
	curFilter: ILeadsFilterParams;
	filterRef: React.MutableRefObject<ILeadsFilterParams>;
	navigate: INavigateFn;
	onComplete: () => void;
}

const SearchBox = withStyles((theme) => ({
	root: {
		color: theme.palette.background.paper,
	},
}))(Input);

const Label = withStyles((theme) => ({
	label: theme.typography.inputLabel,
}))(FormControlLabel);

export const FiltersForm: React.FC<IFiltersProps> = ({
	curFilter,
	filterRef,
	navigate,
	onComplete,
}) => {
	const styles = customStyles();

	const [filterValues, setFilterValues] = useState(curFilter);

	const handleFilterUpdate = useCallback(() => {
		setFilterValues((curVal) => {
			const query = Object.entries(curVal)
				.filter(([key, val]) => key !== "next")
				.reduce(
					(acc: { [key: string]: string }, [key, val]) =>
						Object.assign(acc, {
							[key]:
								key.startsWith("date") && val
									? dayjs(val).format()
									: val,
						}),
					{},
				);

			// @ts-ignore
			navigate(
				{
					query: Object.keys(query).reduce(
						(agg, key) =>
							query[key] ? { ...agg, [key]: query[key] } : agg,
						{},
					),
				},
				{ keepQuery: true },
			);

			mp.fireEvent({
				event: "filteredLeads",
				context: {
					searchString: curVal.search,
					dateRange:
						!curVal.dateFrom && !curVal.dateTo
							? "N/A"
							: `${curVal.dateFrom || "N/A"} - ${
									curVal.dateTo || " N/A"
							  }`,
					showCreditVerified: yn(curVal.creditVerified, {
						default: false,
					}),
				},
			});
			return curVal;
		});
	}, [navigate]);

	const handleRemoveFilters = () => {
		setFilterValues({
			dateFrom: "",
			dateTo: "",
			search: "",
			assignee: "",
			creditVerified: "",
			product: "",
			state: "",
			priority: "",
		});
		navigate({ path: "/leads" });
	};

	const updateFilterValue = useMemo(() => {
		const keys: (keyof ILeadsFilterParams)[] = [
			"creditVerified",
			// "idVerified",
			"dateFrom",
			"dateTo",
			"search",
			"priority",
			"state",
		];
		const handler =
			<K extends keyof LeadsFilterInputHandler>(
				field: K,
			): ((val: ILeadsFilterParams[K]) => void) =>
			(val) => {
				setFilterValues((curVal) => {
					const newFilters = {
						...curVal,
						[field]: val,
					};
					filterRef.current = newFilters;
					return newFilters;
				});
			};

		return keys.reduce((acc, key) => {
			acc[key] = handler(key);
			return acc;
		}, {} as LeadsFilterInputHandler);
	}, [filterRef]);

	const priorityOptions = ["LOW", "MEDIUM", "HIGH"];

	const handleChangeStatus = (
		event: React.ChangeEvent<{ value: unknown }>,
	) => {
		updateFilterValue.state(event.target.value as string);
	};

	const handleChangePriority = (
		event: React.ChangeEvent<{ value: unknown }>,
	) => {
		updateFilterValue.priority(event.target.value as string);
	};

	const formatLeadStatus = (state: LeadState) => {
		switch (state) {
			case LeadState.New:
				return "New";
			case LeadState.AppointmentBooked:
				return "Appointment booked";
			case LeadState.Contacted:
				return "Contacted";
			case LeadState.Dead:
				return "Dead";
			case LeadState.Sold:
				return "Sold";
			case LeadState.Working:
				return "Working";
			default:
				return "New";
		}
	};

	const formatLeadPriority = (priority: LeadPriority) => {
		switch (priority) {
			case LeadPriority.Low:
				return "Low";
			case LeadPriority.Medium:
				return "Medium";
			case LeadPriority.High:
				return "High";
			default:
				return "Medium";
		}
	};

	const Selector = ({
		value,
		label,
		options,
		onChange,
	}: {
		value: string;
		label: string;
		options: { label: string; value: string }[];
		onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
	}) => {
		const styles = customStyles();

		return (
			<div className={styles.selectOption}>
				<Typography variant="caption" className={styles.selectLabel}>
					{label}:
				</Typography>
				<Select
					id={`select-${label}`}
					className={styles.select}
					value={value}
					onChange={onChange}
					MenuProps={{
						getContentAnchorEl: () => null!,
					}}
				>
					{options.map(({ label, value }) => {
						return (
							<MenuItem key={`option-${value}`} value={value}>
								{label}
							</MenuItem>
						);
					})}
				</Select>
			</div>
		);
	};

	return (
		<form
			noValidate
			autoComplete="off"
			onSubmit={(e) => e.preventDefault()}
			className={styles.mainContent}
		>
			<SearchBox
				id="leads-list-search"
				inputLabel="Search"
				variant="outlined"
				InputLabelProps={{
					className: styles.searchLabel,
					classes: {
						focused: styles.searchLabel,
					},
				}}
				InputProps={{
					classes: {
						input: styles.inputControl,
						root: styles.searchRoot,
					},
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
					endAdornment: filterValues.search.length > 0 && (
						<IconButton
							className={styles.removeSearch}
							onClick={() => {
								updateFilterValue.search("");
							}}
						>
							<ResetIcon />
						</IconButton>
					),
				}}
				value={filterValues.search}
				onChange={(event) =>
					updateFilterValue.search(event.currentTarget.value)
				}
			/>
			<Selector
				value={filterValues.priority}
				onChange={handleChangePriority}
				label="Priority"
				options={Object.values(LeadPriority).map((priority) => ({
					label: formatLeadPriority(priority),
					value: priority,
				}))}
			/>
			<Selector
				value={filterValues.state}
				onChange={handleChangeStatus}
				label="Status"
				options={Object.values(LeadState).map((status) => ({
					label: formatLeadStatus(status),
					value: status,
				}))}
			/>
			<Box className={styles.switchFilters}>
				{/* <Label
                    className={styles.switch}
                    control={(
                        <Switch
                            checked={filterValues.idVerified === "true"}
                            onChange={(e) => {
                                const newVal = e.currentTarget.checked ? "true" : "";
                                updateFilterValue.idVerified(newVal);
                            }}
                        />
                    )}
                    labelPlacement="start"
                    label="ID Verified"
                /> */}
				<Label
					className={styles.switch}
					control={
						<Switch
							checked={filterValues.creditVerified === "true"}
							onChange={(e) => {
								const newVal = e.currentTarget.checked
									? "true"
									: "";
								updateFilterValue.creditVerified(newVal);
							}}
						/>
					}
					labelPlacement="start"
					label="Credit Verified"
				/>
			</Box>
			<Box className={styles.dateFilter}>
				<label className={styles.dateLabel}>From</label>
				<DateFilterComponent
					value={filterValues.dateFrom}
					onChange={(_, value) => {
						updateFilterValue.dateFrom(dateToInternal(value || ""));
					}}
					InputProps={{
						startAdornment: (
							<IconButton
								className={clsx(
									styles.resetAdornment,
									filterValues.dateFrom.length > 0 && "show",
								)}
								style={{ order: 1, padding: 5 }}
								onClick={() => {
									updateFilterValue.dateFrom("");
								}}
							>
								<ResetIcon />
							</IconButton>
						),
					}}
					InputAdornmentProps={{
						position: "end",
						style: {
							order: 2,
							marginRight: 0,
							marginLeft: 0,
							padding: 5,
						},
						classes: {
							positionEnd: styles.dateAdornment,
						},
					}}
				/>
				<label className={styles.dateLabel}>To</label>
				<DateFilterComponent
					value={filterValues.dateTo}
					onChange={(_, value) => {
						updateFilterValue.dateTo(dateToInternal(value || ""));
					}}
					InputProps={{
						startAdornment: (
							<IconButton
								className={clsx(
									styles.resetAdornment,
									filterValues.dateTo.length > 0 && "show",
								)}
								style={{ order: 1, padding: 5 }}
								onClick={() => {
									updateFilterValue.dateTo("");
								}}
							>
								<ResetIcon />
							</IconButton>
						),
					}}
					InputAdornmentProps={{
						position: "end",
						style: {
							order: 2,
							marginRight: 0,
							marginLeft: 0,
							padding: 5,
						},
						classes: {
							positionEnd: styles.dateAdornment,
						},
					}}
				/>
			</Box>
			<PrimaryButton
				type="submit"
				onClick={() => {
					handleFilterUpdate();
					onComplete();
				}}
			>
				{"Apply"}
			</PrimaryButton>
			<PrimaryButton
				type="submit"
				onClick={() => {
					handleRemoveFilters();
					onComplete();
				}}
			>
				Clear Filters
			</PrimaryButton>
		</form>
	);
};
