import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

import { Portal } from "~/components/Portal";
import { Typography } from "~/components/Typography";
import { LooseLink, ILooseLinkProps } from "~/components/Link";

import { SECONDARY_NAV } from "../portals";
import { useSecondNavStyles, ISecondNavStyleProps } from "../styles";

interface ISecondaryNavLinkProps extends ILooseLinkProps, ISecondNavStyleProps {
	className?: string;
	active?: boolean;
	children?: React.ReactNode | React.ReactNode[];
}

const SecondaryNavLink: React.FC<ISecondaryNavLinkProps> = ({
	to,
	children,
	className,
	active,
	position = "left",
	...linkProps
}) => {
	const styles = useSecondNavStyles({ position });

	return (
		<LooseLink
			to={to}
			className={clsx(
				styles.secondLeftNavItem,
				className,
				active && "active",
			)}
			{...linkProps}
		>
			<Button className={styles.secondLeftNavButton}>
				<Typography
					variant="subtitle1"
					className={clsx(
						styles.secondLeftNavLabel,
						active && "active",
					)}
				>
					{children}
				</Typography>
			</Button>
		</LooseLink>
	);
};

export interface INavLink extends Omit<ISecondaryNavLinkProps, "children"> {
	label?: string;
	content?: React.ReactNode;
}

interface ISecondaryNavProps extends ISecondNavStyleProps {
	title?: string;
	items?: INavLink[];
	classes?: {
		root?: string;
	};
	children?: React.ReactNode[];
}

const SecondaryNavContent: React.FC<ISecondaryNavProps> = ({
	title = "",
	items = [],
	classes = {},
	position = "left",
}) => {
	const styles = useSecondNavStyles({ position });

	return (
		<Box className={clsx(styles.secondLeftNavRoot, classes.root)}>
			<Box className={styles.secondLeftNavTitleContainer}>
				<Box className={styles.secondLeftNavTitle}>
					<Typography variant="title">{title}</Typography>
				</Box>
			</Box>
			<Box className={styles.secondLeftNavItemContainer}>
				{items.map(({ to, active, label, content, ...linkProps }) => (
					<SecondaryNavLink
						key={`secondary-nav-link-${label}-${to}`}
						to={to}
						active={active}
						position={position}
						{...linkProps}
					>
						{content || label}
					</SecondaryNavLink>
				))}
			</Box>
		</Box>
	);
};

/**
 * Allows you to define a secondary nav statically.  It will be much more difficult
 * to handle events from the nav from within your local view's tree.
 *
 * Should be wrapped by a `<LayoutComponent />`, and passed to `secondleftNav` of
 * `layoutProps` in `createDashboardLayout()`
 */
export const SecondaryNavStatic: React.FC<ISecondaryNavProps> = (props) => (
	<SecondaryNavContent {...props} />
);

/**
 * Allows you to define a secondar nav dynamically.  This will fit within your local view's tree,
 * and place the secondary nav within the layout.  You will be able to handle events within your
 * local view's tree without needing to worry about having a higher level state handler.
 *
 * Can be used as-is.
 */
export const SecondaryNavPortal: React.FC<ISecondaryNavProps> = (props) => (
	<Portal id={SECONDARY_NAV}>
		<SecondaryNavContent {...props} />
	</Portal>
);
