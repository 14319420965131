import { makeStyles } from "@material-ui/core/styles";

export const customStyles = makeStyles((theme) => ({
	mainContent: {
		display: "flex",
		flexDirection: "column",
		gap: 8,
		padding: "32px 16px",
	},
	removeSearch: {
		padding: 4,
	},
	resetAdornment: {
		visibility: "hidden",
		"&.show": {
			visibility: "visible",
		},
	},
	dateLabel: {
		...theme.typography.inputLabel,
		marginRight: 10,
	},
	dateAdornment: {
		"& > button": {
			padding: 5,
		},
	},
	dateFilter: {
		display: "flex",
		flexDirection: "column",
		gap: 8,
		marginBottom: 16,
	},
	switchFilters: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		marginTop: 8,
	},
	switch: {
		display: "flex",
		gap: 8,
		margin: 0,
	},
	inputControl: {
		padding: "15px 15px",
		paddingRight: 0,
		width: "100%",
	},
	searchRoot: {
		backgroundColor: "#FDFDFD",
	},
	searchLabel: {
		color: `${theme.palette.text.primary} !important`,
	},
	select: {
		width: "66%",
		margin: 4,
	},
	selectOption: {
		display: "flex",
		gap: 8,
	},
	selectLabel: {
		alignSelf: "end",
		width: "33%",
	},
}));
