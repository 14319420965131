import React from "react";

import type { LeadProductsDetails } from "~/model/leads";

import { ILeadsFilterParams } from "@utils/leads";

import { SecondaryNavPortal } from "@layouts/Dashboard/SecondaryNav";
import { ProductFilterContent } from "@leads/LeadList/ProductFilter/ProductFilterContent";

interface IProductFilterProps {
	curFilter: ILeadsFilterParams;
	productList: LeadProductsDetails[];
	loading?: boolean;
}

export const ProductFilter: React.FC<IProductFilterProps> = ({
	curFilter,
	productList,
	loading,
}) => {
	return (
		<SecondaryNavPortal
			title="Leads"
			items={productList.map((product) => {
				const productTitle = product.title;
				const productType = product.name;
				const leadCount = product.count;
				const showAllLeads = productType === "GLOBAL";

				return {
					to: showAllLeads ? "?" : `?product=${productType}`,
					active: showAllLeads
						? !curFilter.product
						: curFilter.product === productType,
					removeQuery: showAllLeads ? ["product"] : undefined,
					keepQuery: true,
					content: (
						<ProductFilterContent
							title={productTitle}
							count={leadCount}
							product={product}
							loading={loading}
						/>
					),
				};
			})}
		/>
	);
};
