import { makeStyles } from "@material-ui/core";

export const customStyles = makeStyles(() => {
	return {
		popover: {
			padding: "8px 12px",
			borderRadius: 8,
			border: "1px solid #CACACA",
			backgroundColor: "#FDFDFD",
			color: "#000000",
			zIndex: 1000,
			position: "absolute",
			whiteSpace: "nowrap",
			bottom: 24,
		},
	};
});
