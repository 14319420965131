import React, {
	Fragment,
	useEffect,
	useState,
	useCallback,
	useRef,
	useMemo,
} from "react";

import dayjs from "dayjs";

import { useExportList, ILeadFilter, DatetimeFilter } from "@api/leads";

import { ILeadsFilterParams, getLeadFilterInput } from "@utils/leads";
import { mp } from "@utils/mixpanel";

import { LeadsListExport, LeadsListEdges, EXPORT_LIMIT } from "~/model/leads";

import { dateFormat } from "~/meta";

import { makeStyles } from "@material-ui/core/styles";
import { ButtonProps } from "@material-ui/core/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import Chip from "@material-ui/core/Chip";
import EventIcon from "@material-ui/icons/Event";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import AutoComplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { SecondaryButton } from "~/components/Button/SecondaryButton";
import { Typography } from "~/components/Typography";
import { PrimaryButton } from "~/components/Button/PrimaryButton";
import { AppDialog } from "~/components/Modal";

import { makeLeadsExport } from "@leads/LeadList/make-leads-export";

import DownloadIcon from "~/assets/vectors/download-icon.svg";

const useStyles = makeStyles((theme) => ({
	filter: {
		display: "flex",
		justifyContent: "center",
		flexWrap: "wrap",
		"& > *": {
			margin: theme.spacing(0.5),
		},
		marginBottom: theme.spacing(6),
		width: 300,
	},
	wrapper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	button: {
		minWidth: 136,
		padding: "10px 24px",
	},
}));

interface IDownloadingScreenProps {
	filter?: ILeadFilter;
	done: VoidFunction;
}

const DownloadingScreen: React.FC<IDownloadingScreenProps> = ({
	filter = {},
	done,
}) => {
	const styles = useStyles();

	const [progress, setProgress] = useState(0);
	const [total, setTotal] = useState(0);
	const [cancelled, setCancelled] = useState(false);

	const isDownloading = useRef(false);
	const cancel = useRef(false);
	const exportFilter = useRef(filter);
	const finished = useRef(done);

	finished.current = done;
	cancel.current = cancelled;

	const { fetchLeads } = useExportList(EXPORT_LIMIT);

	const fromDate = useMemo(
		() =>
			filter?.dates?.greaterThanOrEqualTo
				? dayjs(filter.dates.greaterThanOrEqualTo)
						.utc()
						.format(dateFormat)
				: "N/A",
		[filter?.dates?.greaterThanOrEqualTo],
	);
	const toDate = useMemo(
		() =>
			filter?.dates?.lessThan
				? dayjs(filter.dates.lessThan)
						.utc()
						.subtract(1, "day")
						.format(dateFormat)
				: "N/A",
		[filter?.dates?.lessThan],
	);

	const eventDateRange = useMemo(() => {
		if (!filter.dates) return "N/A";
		return `${fromDate} - ${toDate}`;
	}, [filter.dates, fromDate, toDate]);

	useEffect(() => {
		if (isDownloading.current) return;
		isDownloading.current = true;

		mp.fireEvent({
			event: "exportedCSV",
			context: {
				searchString: filter.search || "",
				dateRange: eventDateRange,
				showCreditVerified: filter.creditVerified || false,
			},
		});

		(async () => {
			let complete = false;
			const leads: LeadsListEdges = [];
			let after;
			let total = 0;
			let progress = 0;

			while (!complete && !cancel.current) {
				const partial: LeadsListExport | undefined = await fetchLeads(
					after,
					exportFilter.current,
				);

				partial?.leads && leads.push(...partial.leads);

				if (partial?.pageInfo?.hasNextPage) {
					after = partial?.pageInfo?.endCursor;
				} else {
					complete = true;
				}

				total += partial?.leads?.length || 0;
				progress = Math.ceil(
					(total / (partial?.totalCount || 0)) * 100,
				);
				setProgress(progress);
				setTotal(partial?.totalCount || 0);
			}

			if (leads.length && !cancel.current) {
				const data = makeLeadsExport(leads);
				const blob = new Blob([data], {
					type: "text/plain;charset=utf-8",
				});

				const blobUrl = URL.createObjectURL(blob);

				const link = document.createElement("a");
				link.style.display = "none";
				link.href = blobUrl;
				link.download = `ac-lead-list-${new Date().toISOString()}.csv`;
				document.body.appendChild(link);

				link.click();

				URL.revokeObjectURL(blobUrl);
				document.body.removeChild(link);
				isDownloading.current = false;
				setTimeout(() => {
					finished.current();
				}, 1000);
			}

			if (cancel.current) {
				isDownloading.current = false;
				setTimeout(() => {
					finished.current();
				}, 1000);
			}
		})();
	}, [eventDateRange, fetchLeads, filter.creditVerified, filter.search]);

	const doCancel = useCallback(() => {
		setCancelled(true);
		if (!isDownloading.current) finished.current();
	}, [setCancelled]);

	return (
		<Box
			position="relative"
			display="flex"
			flexDirection="column"
			justifyContent="center"
			my={4}
			mx={2}
		>
			<Box
				position="relative"
				display="inline-flex"
				mt={4}
				mb={4}
				justifyContent="center"
			>
				<CircularProgress
					size={160}
					thickness={5}
					value={progress}
					variant="determinate"
				/>
				<Box
					top={0}
					left={0}
					bottom={0}
					right={0}
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography
						variant="h3"
						component="div"
						color="textSecondary"
					>
						{isNaN(progress)
							? "No Leads to Export"
							: `${progress}%`}
					</Typography>
				</Box>
			</Box>
			<Box
				position="relative"
				display="flex"
				mt={2}
				mb={1}
				justifyContent="center"
			>
				<Chip label={`Total Leads: ${total}`} />
			</Box>
			<Box
				position="relative"
				display="flex"
				mt={4}
				mb={2}
				justifyContent="center"
			>
				<FilterListIcon fontSize="small" />
				<Typography variant="button">{`FILTERS`}</Typography>
			</Box>
			<Box className={styles.filter}>
				{filter?.search && (
					<Chip
						variant="outlined"
						label={`${filter?.search}`}
						icon={<SearchIcon />}
					/>
				)}

				{filter?.dates?.greaterThanOrEqualTo && (
					<Chip
						variant="outlined"
						label={`From: ${fromDate}`}
						icon={<EventIcon />}
					/>
				)}

				{filter?.dates?.lessThan && (
					<Chip
						variant="outlined"
						label={`To: ${toDate}`}
						icon={<EventIcon />}
					/>
				)}

				{filter?.creditVerified && (
					<Chip
						variant="outlined"
						label="Verified"
						icon={<VerifiedUserIcon />}
					/>
				)}
			</Box>

			<Box position="relative" textAlign="center" width="100%">
				<PrimaryButton
					onClick={doCancel}
					disabled={cancelled}
					style={{ width: "100%" }}
				>
					{`CANCEL`}
				</PrimaryButton>
				<Typography
					variant="body2"
					style={{
						position: "absolute",
						display: cancelled ? undefined : "none",
						width: "100%",
					}}
				>
					<i>{"Cancelling download..."}</i>
				</Typography>
			</Box>
		</Box>
	);
};

interface IFilterScreenProps {
	onExport: () => void;
	onCancel: () => void;
	setExportFilter: React.Dispatch<React.SetStateAction<ILeadFilter>>;
	origFilter: ILeadFilter;
}

type Option = {
	title: string;
} & Pick<DatetimeFilter, "greaterThanOrEqualTo" | "lessThan">;

const presets: Option[] = [
	{
		title: "Week To Date",
		greaterThanOrEqualTo: dayjs()
			.utc()
			.startOf("day")
			.startOf("week")
			.toISOString(),
		lessThan: dayjs().utc().add(1, "day").toISOString(),
	},
	{
		title: "Month To Date",
		greaterThanOrEqualTo: dayjs()
			.utc()
			.startOf("day")
			.startOf("month")
			.toISOString(),
		lessThan: dayjs().utc().add(1, "day").toISOString(),
	},
	{
		title: "Year To Date",
		greaterThanOrEqualTo: dayjs()
			.utc()
			.startOf("year")
			.startOf("day")
			.toISOString(),
		lessThan: dayjs().utc().add(1, "day").toISOString(),
	},
	{
		title: "Last Week",
		greaterThanOrEqualTo: dayjs()
			.utc()
			.startOf("day")
			.startOf("week")
			.subtract(1, "week")
			.toISOString(),
		lessThan: dayjs()
			.utc()
			.subtract(1, "week")
			.endOf("week")
			.add(1, "day")
			.startOf("day")
			.toISOString(),
	},
	{
		title: "Last Month",
		greaterThanOrEqualTo: dayjs()
			.utc()
			.startOf("day")
			.startOf("month")
			.subtract(1, "month")
			.toISOString(),
		lessThan: dayjs()
			.utc()
			.startOf("month")
			.subtract(1, "month")
			.endOf("month")
			.add(1, "day")
			.startOf("day")
			.toISOString(),
	},
];

const FilterScreen: React.FC<IFilterScreenProps> = ({
	onExport,
	onCancel,
	setExportFilter,
	origFilter,
}) => {
	const [isOpen, setOpen] = useState(false);
	const [hasPredefined] = useState(() => Object.keys(origFilter).length > 0);
	const [options] = useState(() =>
		presets
			.slice(0)
			.concat(
				hasPredefined
					? [{ title: "Use Existing Filters" }]
					: [{ title: "All" }],
			),
	);
	const [isReady, setReady] = useState(hasPredefined);

	const handleChange = useCallback(
		(event: any, newValue: Option | null) => {
			const origGt = origFilter.dates?.greaterThanOrEqualTo;
			const origLt = origFilter.dates?.lessThan;
			if (newValue) {
				const gt = newValue?.greaterThanOrEqualTo;
				const lt = newValue?.lessThan;
				setExportFilter(({ dates = {}, ...curFilter }) => ({
					...curFilter,
					dates: {
						...dates,
						...{
							greaterThanOrEqualTo: gt || origGt,
							lessThan: lt || origLt,
						},
					},
				}));
				setReady(true);
			} else {
				setExportFilter(({ dates = {}, ...curFilter }) => ({
					...curFilter,
					dates: {
						...dates,
						greaterThanOrEqualTo: origGt,
						lessThan: origLt,
					},
				}));
				setReady(false);
			}
		},
		[setExportFilter, origFilter],
	);

	const handleAction = () => {
		if (!isReady) return onCancel();
		onExport();
	};

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Box>
			<ClickAwayListener onClickAway={handleClose}>
				<AutoComplete
					open={isOpen}
					onOpen={handleOpen}
					onClick={handleOpen}
					options={options}
					defaultValue={
						hasPredefined ? options.slice(-1).pop() : undefined
					}
					getOptionLabel={(option) => option.title || "Unknown"}
					style={{ width: 250 }}
					renderInput={(params) => (
						<TextField
							label="Select Preset"
							variant="outlined"
							onClick={handleOpen}
							{...params}
							InputProps={{
								...params.InputProps,
							}}
						/>
					)}
					onChange={handleChange}
				/>
			</ClickAwayListener>
			<Box
				position="relative"
				display="flex"
				flexDirection="column"
				justifyContent="center"
				marginTop={2}
			>
				<PrimaryButton onClick={handleAction}>
					{isReady ? "EXPORT" : "CANCEL"}
				</PrimaryButton>
			</Box>
		</Box>
	);
};

interface IExportCsvProps {
	filter?: React.MutableRefObject<ILeadsFilterParams>;
	done: VoidFunction;
}

const enum DialogState {
	Filtering,
	Downloading,
	None,
}

const ExportCsv: React.FC<IExportCsvProps> = ({ filter, done }) => {
	const [origFilter] = useState<ILeadFilter>(() =>
		filter?.current ? getLeadFilterInput(filter.current) : {},
	);
	const [exportFilter, setExportFilter] = useState<ILeadFilter>(origFilter);

	const [dialogState, setDialogState] = useState(() =>
		exportFilter.dates?.greaterThanOrEqualTo
			? DialogState.Downloading
			: DialogState.Filtering,
	);
	const setDownloading = useCallback(
		() => setDialogState(DialogState.Downloading),
		[],
	);
	const closeDialog = useCallback(() => {
		setDialogState(DialogState.None);
		done();
	}, [done]);

	const dialogOpen = dialogState !== DialogState.None;
	const downloading = dialogState === DialogState.Downloading;
	const filtering = dialogState === DialogState.Filtering;

	const handleClose = () => {
		closeDialog();
	};

	return (
		<AppDialog
			open={dialogOpen}
			title="CSV Download"
			maxWidth="lg"
			onClose={handleClose}
		>
			{downloading && (
				<DownloadingScreen filter={exportFilter} done={done} />
			)}
			{filtering && (
				<FilterScreen
					onExport={setDownloading}
					onCancel={handleClose}
					setExportFilter={setExportFilter}
					origFilter={origFilter}
				/>
			)}
		</AppDialog>
	);
};

interface ExportButtonProps extends Omit<ButtonProps, "onClick"> {
	filter: React.MutableRefObject<ILeadsFilterParams>;
}

export const ExportCsvButton: React.FC<ExportButtonProps> = ({
	className,
	disabled,
	filter,
	...props
}) => {
	const styles = useStyles();
	const [exporting, setExporting] = useState(false);

	const toggleExport = useCallback(() => {
		setExporting((val) => !val);
	}, []);

	const isDisabled = !!exporting;

	return (
		<Fragment>
			<div className={styles.wrapper}>
				<SecondaryButton
					{...props}
					className={styles.button}
					startIcon={
						<DownloadIcon
							style={{ opacity: `${isDisabled ? "0.2" : ""}` }}
						/>
					}
					disabled={!!exporting}
					onClick={toggleExport}
				>
					<Typography variant="buttonLabel">Export CSV</Typography>
				</SecondaryButton>
			</div>
			{exporting && <ExportCsv filter={filter} done={toggleExport} />}
		</Fragment>
	);
};
