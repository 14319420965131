import { useAuth } from "@api/auth";
import { useCreateTestLead } from "@api/leads";
import React from "react";

import { PrimaryButton } from "~/components/Button";

import { customStyles } from "./styles";

const CreateTestLeadButton = () => {
	const styles = customStyles();
	const { user, selectedCompany } = useAuth();

	const {
		actions: { createTestLead },
	} = useCreateTestLead();

	const onClick = async () => {
		try {
			if (!selectedCompany) {
				alert("Please select a company to create a test lead.");
				return;
			}
			const result = await createTestLead(
				selectedCompany?.id,
				location?.href,
			);
			console.log(result);
		} catch (error) {
			console.error(error);
		}
	};

	const canView = user?.autocorpAdmin || user?.email.includes("@autocorp.ai");
	if (canView && !selectedCompany) {
		return null;
	}

	return (
		<PrimaryButton className={styles.testLeadButton} onClick={onClick}>
			Create Test Lead
		</PrimaryButton>
	);
};

export default CreateTestLeadButton;
