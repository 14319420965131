import React, { Fragment, useState } from "react";

import { customStyles } from "./styles";

import { IPopover } from "./types";

const Popover = ({ children, text }: IPopover) => {
	const [style, setStyle] = useState({ display: "none" });
	const styles = customStyles();
	return (
		<div
			onMouseEnter={() => {
				setStyle({ display: "block" });
			}}
			onMouseLeave={() => {
				setStyle({ display: "none" });
			}}
		>
			<Fragment>{children}</Fragment>
			<div className={styles.popover} style={style}>
				{text}
			</div>
		</div>
	);
};

export default Popover;
