import { makeStyles } from "@material-ui/core";

export const customStyles = makeStyles((theme) => ({
	select: {
		width: "66%",
		maxWidth: 120,
		marginTop: 8,
	},
	selectOption: {
		display: "flex",
		gap: 8,
		width: 220,
		[theme.breakpoints.down(theme.breakpoints.values.xs)]: {
			flexDirection: "column",
			width: 150,
		},
	},
	selectLabel: {
		alignSelf: "end",
		width: "33%",
		[theme.breakpoints.down(theme.breakpoints.values.xs)]: {
			display: "none",
		},
	},
}));
